.App {
  text-align: center;
  min-height:100vh;
  margin:0;
  padding:0;
  max-width: 100vw;
  display: block;
  overflow-y: hidden;
}

.MuiPaper-rounded{
  border-radius: 8px !important;
}
.Md-InputSearch {
  border-radius: 50px !important;
}

.Md-Button {
  border-radius: 50px !important;
}
.Md-Button-Add {
  color: #FF7233 !important;
  border: 1px solid #FF7233 !important;
}
.Md-Button-Add:hover {
  background: rgba(255,114,51,0.05) !important;
}

/*#######################################*/
.Md-Sidebar-Computer {
  height: 95vh;
  margin-left:15px;
  margin-top:15px;
  position:fixed;
  white-space: nowrap;
  box-sizing: border-box;
}

.Md-Sidebar-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left:15px;
  margin-top:15px;
  height: 40px;
  width: 40px;
  position:fixed;
  white-space: nowrap;
  box-sizing: border-box;
  border-radius: 50px;
}

.Md-Sidebar-Computer.activated {
  animation: Md-Sidebar-Computer-activated 300ms forwards;
}

.Md-Sidebar-Computer.disactivated {
  animation: Md-Sidebar-Computer-disactivated 1s forwards;
}

@keyframes Md-Sidebar-Computer-activated{
  0% {
    width: 65px;
  }
  100% {
    width: 275px;
  }
}

@keyframes Md-Sidebar-Computer-disactivated{
  0% {
    width: 275px;
  }
  100% {
    width: 65px;
  }
}


/*#######################################*/
#Md-Container {
  display: flex;
  padding: 15px 50px 15px 0;
  /*height: calc(100vh - 30px);*/
  min-height: calc(100vh - 30px);
}


#Md-Container.mobile {
  margin-left:5%;
  padding: 15px 15px 15px 0;
}

#Md-Container.activated {

  animation: Md-Container-activated 300ms forwards;
}

#Md-Container.disactivated {
  animation: Md-Container-disactivated 1s forwards;
}

@keyframes Md-Container-activated{
  0% {
    margin-left: 115px;
  }
  100% {
    margin-left: 325px;
  }
}
@keyframes Md-Container-disactivated{
  0% {
    margin-left: 325px;
  }
  100% {
    margin-left: 115px;
  }
}

/*#######################################*/