.DropZoneScenario{
    display: block;
    height: 25px;
    width: 100%;
    opacity: 0;
    border: 1px dashed grey;
}

.DropZoneScenario.hover{
    border-radius: 10px;
}

.DropZoneScenario.hoverY{
    border-radius: 10px;
    height: 150px !important;
    opacity: 1 !important;
}


.DropZoneScenario.animate{
    animation: open 0.5s forwards;
}

.DropZoneScenario.endHover{
    animation: close 0.5s forwards;
}

@keyframes open{
    0% {
        height: 25px;
        opacity: 0;
    }
    100% {
        height: 150px;
        opacity: 1;
    }
}

@keyframes close{
    0% {
        height: 150px;
        opacity: 1;
    }
    100% {
        height: 25px;
        opacity: 0;
    }
}