
td {
    vertical-align: middle;
    text-align: center;
}

td svg{
    vertical-align: middle;
    text-align: center;
}
.textStep {
    text-align: left;
}

.trash {

    border: 1px solid red;
    border-radius: 5px;
    color: red;
}

.trash:hover {

    border: 1px solid red;
    border-radius: 5px;
    color: white;
    background: red;
}