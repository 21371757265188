.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside .description {
    margin-bottom: 10px;
}
.dndnodeext {
    padding: 6px;
    border: 1px solid #FF7233;
    border-radius: 10px;
    margin: 10px;
    cursor: grab;
}
.dndnodeext.endPath {
    /*border: 1px solid #c50000;*/
    /*color: red;*/
    color: white;
    padding: 6px;
    background: rgba(255,114,51,1);
}
.dndnodeext.endPath.edit {
    padding: 15px;
    color: black;
    background: white;
    border: 5px solid #FF7233;
    /*border-radius: 5px !important;*/
}
.dndnodeext.template {
    /*border: 1px solid #c50000;*/
    /*color: red;*/
    border:3px dashed #FF7233;
    border-radius: 2px;
}

.dndnodeext.error {
    border: 1px solid #c50000;
}

.dndflow .dndnode {
    height: 20px;
    padding: 4px;
    /*border: 1px solid #1a192b;*/
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.reactflow-wrapper{
    height: 100% !important;
}


.reactflow-wrapper.dialog{
    height: 100% !important;
}

.reactflow-wrapper.force-full-height{
    height: 100% !important;
    border:1px solid black;
    border-radius: 25px;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

.dndflow .selectall {
    margin-top: 10px;
}

/*@media screen and (min-width: 768px) {*/
/*    .dndflow {*/
/*        flex-direction: row;*/
/*    }*/

/*    .dndflow aside {*/
/*        width: 20%;*/
/*        max-width: 250px;*/
/*    }*/
/*}*/


.touchdevice-flow .react-flow__handle {
    width: 50px;
    height: 10px;
    border-radius: 3px;
    border-width: 2px;
    border-color: #FF7233;
    background: white;
}
.react-flow__handle.endPath {
    border: 1px solid #c50000;
    background: #c50000;
}

.react-flow__node-input {
    border-color:green;
}
.react-flow__node-default {
    border-color:#FF7233;
}
.react-flow__node-error {
    border-color:#c50000;
    animation: 3s linear 0s infinite alternate animeBgColorError;

}
@keyframes animeBgColorError {
    0%{
        background: white;
        color:#c50000;
    }
    100%{
        background: #c50000;
        color:white;
    }
}

.touchdevice-flow .react-flow__handle.connecting {
    animation: bounce 1600ms infinite ease-out;
}

/*@keyframes bounce {*/
/*    0% {*/
/*        transform: translate(0, -50%) scale(1);*/
/*    }*/
/*    50% {*/
/*        transform: translate(0, -50%) scale(1.1);*/
/*    }*/
/*}*/

.react-flow__node-custom{
    max-width: 170px;
}
.react-flow__node-custom .react-flow__handle-top{
    transform: translate(-50%,50%)
}
.react-flow__node-custom .react-flow__handle-bottom{
    transform: translate(-50%,-50%)
}