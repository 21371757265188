#titleWelcomeInit {
    animation: fadeIn 3s  forwards;
    opacity: 0;
}

#bodyWelcomeInit {
    animation: fadeIn 3s forwards;
    animation-delay: 3s;
    opacity: 0;
}

#btnWelcomeInit {
    animation: fadeIn 3s forwards;
    animation-delay: 6s;
    opacity: 0;
}

@keyframes fadeIn{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#backColor {
    background-color: rgb(255,114,51);
    animation: bodyChangeOp 5s infinite alternate;
}

@keyframes bodyChangeOp{
    0% {
        background-color: rgb(255,114,51);
    }
    100% {
        background-color: rgb(211,74,12);
    }
}


