.context-menu {
    background: white;
    border: solid 1px #1976d2;
    /*box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);*/
    position: absolute;
    z-index: 10;
    border-radius: 0 !important;
}

.context-menu button {
    border: none;
    display: block;
    padding: 0.5em;
    text-align: left;
    width: 100%;
    color:black;
    font-size: 11px;
}

.context-menu button:hover {
    background: rgba(0,0,0,0.1);
}

